<template>
  <div>
    <div class="flix-text-center">
      <a href="#" @click.prevent="callback(usernameNew)" class="flix-btn flix-btn-block flix-btn-success"><flixIcon id="power" /> {{ $t('message.load', {name: $tc('message.webinar', 1)}) }}</a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function,
    username: String
  },
  mounted () {

  },
  computed: {

  },
  data () {
    return {
      usernameNew: this.username
    }
  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>

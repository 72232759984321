import { render, staticRenderFns } from "./join.vue?vue&type=template&id=6552ea79&scoped=true"
import script from "./join.vue?vue&type=script&lang=js"
export * from "./join.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6552ea79",
  null
  
)

export default component.exports